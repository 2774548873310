
import {gameListHttp} from "~/api/comm";
import {Mutation, State} from 'vuex-class'
import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import {allocationCustomers} from "~/api/chat";
import {getWowRegion} from "~/api/goods";
@Component({
  name: "gameDetail",
  components: {
    newImg2: require('~/components/comm/newImg/index2.vue').default,
  },
})
export default class gameDetail extends Vue {
  @Mutation private SET_HOTGAMES_DATA!: any;
  @State((state) => state.web.hotGamesArray) hotGamesArray!: any;
  @Mutation private SET_GAMELIST_DATA!: any;
  @State(state => state.web.gameListArray) gameListArray!: any;
  @Mutation private SET_GAMEFILTER_DATA!: any;
  @State(state => state.web.gamefilterArray) gamefilterArray!: any;
  @Mutation private SET_EDITGOODS_DATA!: any;
  @State((state) => state.web.editGoodsObject) editGoodsObject!: any;
  @Prop(Number) selected_?: any;
  @Prop() customer_type?:any;
  public getCookie: any;
  public openToService: any;
  public errorToastFn: any;
  public gameList:Array<any>=[];
  public letter: string[] = ["热门", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
  public allGameList:any=[];
  private searchGameInput:any = ''
  public isShowSearchBlock: boolean = false;
  public queryParm: object = {rec: 1};
  public selected: string = '5';//大类型选择标识
  public selected_letter: number = 0;//字母选择标识
  public userId: any;
  public number: any = '0';
  public moreFilterList: any[] = [];
  public showMoreArea: boolean = false;
  public clickItem: any = {}
  @Emit('Close') send(needReload: boolean) {}
  created() {
    this.userId = this.getCookie('userId');
    this.getAllGameList()
    if (this.hotGamesArray && this.hotGamesArray.length > 0) {
        this.gameList = this.hotGamesArray.filter((item:any)=> item.isShow == 0 )      
      } else {
         this.getGameList()
      }

  }
/*  @Watch('searchGameInput')
  searchGame(newVal:any) {
    if(newVal == "" || this.allGameList.length == 0) {
      this.isShowSearchBlock = false
      this.allGameList = []
      return
    }
    this.allGameList = this.allGameList.filter((v:any) => v.name.includes(newVal))
    if(this.allGameList.length > 0) {
      this.isShowSearchBlock = true
    }
  }*/
  // 获取筛选游戏列表
  @Emit()
  async getGameList() {
    console.log(this.queryParm, '===queryParm====')
    let {data}:any = await gameListHttp({params: this.queryParm});
    if (this.errorToastFn(data)) {
      if (this.selected_letter === 0) {
        this.gameList = [];
        let hotGameArray: any= [];
        if (this.$store.state.web.distributorsObject.distBaseConf.recGameStr) {
          hotGameArray = this.$store.state.web.distributorsObject.distBaseConf.recGameStr.split(',');
          let i = this.number;
          for (let i = 0; i < hotGameArray.length; i++) {
            let gameFlag = data.data.find((res: any) => res.id == hotGameArray[i]);
            // console.log("gaaaaaaaaaaaaaaaaaaaaaaa")
            if (gameFlag) {
              this.gameList.push(gameFlag);
            }
          }
          this.gameList =  this.gameList.filter((item:any)=> item.isShow == 0 )
          if (!this.hotGamesArray || (this.hotGamesArray && this.hotGamesArray.length < this.gameList.length)) {
            this.SET_HOTGAMES_DATA(this.gameList);
          }
         
        }
      } else {
        this.gameList = data.data.filter((item:any)=> item.isShow == 0 );
      }
    }
  }

  @Emit()
  //选择游戏
  async addOrEdit() {
    try {
      this.toService(this.searchGameInput);
      /* this.$message.success('保存成功')*/
    } catch (e) {
      console.log(e, '添加或者保存链接失败！！！');
    }
  }

  @Emit()
  // 游戏首字母筛选
  chooseStatusType(value: any, index: any) {
    this.selected_letter = index;
    if (value == "热门") {
      this.queryParm["rec"] = 1;
      delete this.queryParm["initial"];
    } else {
      this.queryParm["initial"] = value;
      delete this.queryParm["rec"];
    }
    this.getGameList();
  }

  @Emit()
  // 游戏类型筛选
  chooseGameType(number: any) {
    if (this.$route.query.goodsTypeId) {
      this.queryParm["goodsTypeId"] = this.$route.query.goodsTypeId;
    }
    if (number == 5) {
      // this.queryParm["rec"] = 1;
      delete this.queryParm["type"];
      this.selected = number;
      setTimeout(() => {
        this.getGameList();
        console.log(this.gameList,"5201314","209")
      }, 50)
    } else {
      this.queryParm["type"] = number;
      if (number == 4) {
        delete this.queryParm["goodsTypeId"];
      }
      this.selected = number;
      this.getGameList();
      console.log(this.gameList,"5201314","209")
    }
  }

  handleClickGameArea(id:number){
   
      this.toService(id)
      this.showMoreArea  = false
  }
    async handleChooseGame(item:any){      
      this.showMoreArea = false
    this.clickItem = {}
    const params = {
      gameId: item.id,
    };
    let res: any = await getWowRegion({ params: params });
    let { data } = res.data;
   
    if (res.data.code === 0) {
      if (data.wowList.length > 1) {
        this.moreFilterList = data.wowList;
        this.clickItem = item;
        this.showMoreArea = true
      } else if (data.wowList.length > 0) {
     
        this.handleClickGameArea(data.wowList[0].SonId)
    
      } else {
        this.handleClickGameArea(item.id)
      }
    }
    }

  async getAllGameList() {
    try {
      let {data}: any = await gameListHttp();
      console.log(data, 'ecjkljkljkl');
      if (this.errorToastFn(data)) {
        this.allGameList = data.data;
      } else {
        this.allGameList = [];
      }
    } catch (error) {
      this.allGameList = [];
      console.log(error);
    }
  }

  // 关闭弹窗
  closeBox(needReload:boolean = false) {
    this.$emit('closePopBox_', needReload);
  }

  toService(gameID: any) {
    // 数据验证
    if (gameID == undefined || gameID == "") {
      this.$message.error('请选择游戏名称');
      return false;
    }
    let gameName = ''
    let game = this.allGameList.find((item:any)=>item.id === gameID)
    if(game) gameName = game.name
    if (this.userId) {
      allocationCustomers({userId: this.userId, customerType: this.customer_type, gameId: gameID,gameName}).then((res:any) => {
        if (res.result.customerId !== 0 && res.result.customerId) {
            const params = {
              gameId: gameID,
              gameName
            }
            this.openToService(res.result.customerId,params);
        } else {
          this.$message.warning('当前所有客服处于繁忙状态，请稍后再试！敬请谅解！');
        }
      });
    } else {
      this.$router.push('/login');
    }
  }
}

